import { forwardRef, InputHTMLAttributes, useRef, useState } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { VisibilityIcon, VisibilityOffIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Box } from '../Box/Box';
import { buttonStyle } from '../IconButton/IconButton';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { Stack } from '../Stack/Stack';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputPasswordHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus'
>;

/** Allowed autocomplete values for InputPassword component */
type InputPasswordAutocompleteAttribute = 'current-password' | 'new-password' | 'one-time-code';

type InputPasswordProps = Omit<InputBaseProps, 'isOptional' | 'isDisabled'> & {
  /**
   * Custom `autoComplete` attribute specifically for the password input. More info on when to
   * use a certain value can be found here:
   * https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
   */
  autoComplete: InputPasswordAutocompleteAttribute;
};

type Props = InputPasswordProps & InputPasswordHTMLAttributes;

/**
 * Component to position the input and the ShowPasswordButton. Separate component from
 * InputContainer to make sure ShowPasswordButton doesn't trigger the input focus-visible
 * styles when it's focussed.
 */
const RelativeWrapper = styled('div', {
  position: 'relative',
  marginTop: '$2',
});

const InputContainer = styled('div', {
  padding: '$3',
  /** paddingRight to make space for the ShowPasswordButton */
  paddingRight: 'calc($sizes$targetMinWidth + $3)',
  borderRadius: '$s',
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',
  minHeight: '$inputMinHeight',

  '&:focus-within': {
    outline: '$outlineInputFocus',
    borderColor: '$borderFocus',
  },

  variants: {
    isInvalid: {
      true: {
        border: '$borderWidths$s solid $formBorderError',
        boxShadow: '$shadowError',
      },
      false: {
        '&:hover': {
          boxShadow: '$shadowHover',
          borderColor: '$formBorderHover',
        },
      },
    },
  },
});

const StyledInput = styled('input', {
  width: '100%',
  fontSize: '$BodyL',
  border: 'none',
  color: '$textPrimary',

  '&::placeholder': {
    color: '$textLowEmphasis',
  },

  '&:focus': {
    outline: 'none',
  },
});

export const InputButtonStyles = {
  ...buttonStyle,
  position: 'absolute',
  $$doubleTop: 'calc($sizes$inputMinHeight - $sizes$targetMinHeight)',
  top: 'calc($$doubleTop / 2)',
  $$doubleRight: 'calc($sizes$inputMinHeight - $sizes$targetMinHeight)',
  right: 'calc($$doubleRight / 2)',
};

export const InputButton = styled('button', InputButtonStyles);

export const InputPassword = forwardRef<HTMLInputElement, Props>(
  ({ error, hint, isReadOnly, label, name, ...rest }, ref) => {
    const localRef = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs([ref, localRef]);

    const [type, setType] = useState<'password' | 'text'>('password');
    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });

    const { showPassword, hidePassword, isShown, isHidden } = useI18nTranslations();

    useValidationErrorEvent({ error, name: name }, localRef);

    const toggleShowPassword = () => {
      setType(prevType => (prevType === 'password' ? 'text' : 'password'));
    };

    const buttonLabel = type === 'password' ? showPassword : hidePassword;

    return (
      <Stack direction="column">
        <Stack.Item grow>
          <Label htmlFor={inputId}>
            {label}
            <RelativeWrapper>
              <InputContainer isInvalid={!!error}>
                <StyledInput
                  aria-describedby={describedBy}
                  aria-invalid={error ? true : undefined}
                  id={inputId}
                  name={name}
                  readOnly={isReadOnly}
                  ref={mergedRef}
                  type={type}
                  {...rest}
                />
              </InputContainer>
              <InputButton aria-label={buttonLabel} onClick={toggleShowPassword} type="button">
                <VisuallyHidden>{buttonLabel}</VisuallyHidden>
                {type === 'password' ? (
                  <VisibilityIcon color="iconSecondary" />
                ) : (
                  <VisibilityOffIcon color="iconSecondary" />
                )}
              </InputButton>
            </RelativeWrapper>
          </Label>
        </Stack.Item>
        <VisuallyHidden ariaLive="polite">{`${label} ${type === 'password' ? isHidden : isShown}`}</VisuallyHidden>
        {error ? <Error id={errorId}>{error}</Error> : null}
        {hint ? (
          <Box paddingTop="2">
            <Hint id={hintId}>{hint}</Hint>
          </Box>
        ) : null}
      </Stack>
    );
  },
);
