import { forwardRef } from 'react';

import { useInputIds } from '../../../hooks/useInputIds';
import { CheckIcon } from '../../../icons';
import { styled } from '../../../stitches.config';
import { Box } from '../../Box/Box';
import { Grid } from '../../Grid/Grid';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';
import { Props, StyledCheckedState, StyledCheckbox } from '../Checkbox';

type CheckTileProps = Omit<Props, 'label'> & {
  children: string;
  icon?: JSX.Element;
  subtitle?: string;
};

const CheckTileStyledCheckState = styled(StyledCheckedState, {
  marginTop: 0,
});

const LabelWrapper = styled('span', {
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  minHeight: '72px',
});

const CheckTileStyledCheckbox = styled(StyledCheckbox, {
  marginTop: 0,
  '&:hover:not(:disabled)': {
    boxShadow: 'unset',
    borderColor: 'unset',
  },
});

const Label = styled('label', {
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '$m',
  backgroundColor: '$backgroundPrimary',
  boxShadow: '$s',

  '&:hover': {
    boxShadow: '$shadowHover',
  },

  [`& ${StyledCheckbox}`]: {
    '&::before': {
      position: 'absolute',
      content: '',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '$m',
    },

    '&:checked': {
      '&::before': {
        border: '$borderWidths$m solid $borderSelected',
      },
    },

    '&:focus-visible': {
      '&::before': {
        outline: '$outlineFocus',
      },
    },

    '@supports not selector(:focus-visible)': {
      '&:focus': {
        '&::before': {
          outline: '$outlineFocus',
        },
      },
    },
  },
});

export const CheckTile = forwardRef<HTMLInputElement, CheckTileProps>(
  ({ children, error, hint, isChecked, icon, subtitle, ...restProps }, ref) => {
    const { inputId, describedBy } = useInputIds({ error, hint });

    return (
      <Stack gap="2">
        <Label>
          <Box paddingX="4">
            <Stack gap="3" direction="row" alignY="center">
              <Grid gridTemplateColumns="min-content auto" gridTemplateRows="1fr">
                <CheckTileStyledCheckbox
                  ref={ref}
                  type="checkbox"
                  id={inputId}
                  isInvalid={!!error}
                  aria-describedby={describedBy}
                  aria-invalid={error ? true : undefined}
                  disabled={false}
                  checked={isChecked}
                  {...restProps}
                />
                <CheckTileStyledCheckState isDisabled={false}>
                  <CheckIcon color="iconInverted" size="small" />
                </CheckTileStyledCheckState>
              </Grid>
              <LabelWrapper>
                <Stack gap="3" direction="row" alignY="center">
                  {icon ? <Stack.Item shrink={false}>{icon}</Stack.Item> : null}
                  <Box paddingY="3">
                    <Text weight="bold">{children}</Text>
                    {subtitle && (
                      <Text weight="regular" size="BodyM">
                        {subtitle}
                      </Text>
                    )}
                  </Box>
                </Stack>
              </LabelWrapper>
            </Stack>
          </Box>
        </Label>
      </Stack>
    );
  },
);

StyledCheckedState.displayName = 'styled(CheckedState)';
LabelWrapper.displayName = 'LabelWrapper';
StyledCheckbox.displayName = 'styled(Checkbox)';
CheckTile.displayName = 'CheckTile';
