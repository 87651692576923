import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2F..%2F..%2Flibs%2Fsparky%2Fsrc%2Fcomponents%2FImage%2FImage.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43Ty3KCMBiG4T1XwVIXOBA5aN30TjrhIASFIKCiHe%2B90xSths8kS2f%2BJ%2BqfN4svr%2BRDHiYetb8t26ZdkyW909Ke8Q%2F7RNuZ49xH3PnGulmLh9gKEfM2zVqnpSk7djIJJLITpMhYXvTyLJVmGzHL4%2FL3BzW8Yz3jtYy2EjoKVNHBwV%2Byk%2Bavj%2FkzS%2FtCHm9ex4n%2FN87qN8cfpfn1Yx4ef5XGMzEORz1fzH5WWcqoPXs6chkFzTAX8v9%2FxeKz%2Bj69%2Bcay7duLy0envNUQwP0I8V5iIA6j0NxwDuhppO%2FveT9VxH1ScMcHgII7envnJ6Dok8LX6QK1HRUWwShgBFG4mkaQGERAwHoLkwgiACtlBAkQrVkEBaBnbQQV2LGni6AFKNRGcAYq1kYAXiDJlRGEqgg8l%2FjTClKDCpZgv8ykghWAtbKCFIjOrAIG6KCtoAZLJroKOoAibQUDUIm2AvAESaGsIFJW4PvutILMoAIf7Lc0qWANIFdWkAHRm1VQAnrRVsDBkpe6CnqAVtoKLkCl2grAEyRMWcH97d1%2BAG972tHFCQAA%22%7D"
export var aspectRatioVars = {initial:'var(--_1joxg6c0)',sm:'var(--_1joxg6c1)',md:'var(--_1joxg6c2)',lg:'var(--_1joxg6c3)',xl:'var(--_1joxg6c4)'};
export var borderRadiusVars = {initial:'var(--_1joxg6c5)',sm:'var(--_1joxg6c6)',md:'var(--_1joxg6c7)',lg:'var(--_1joxg6c8)',xl:'var(--_1joxg6c9)'};
export var breakpointVariants = {aspectRatio:{initial:'_1joxg6c1a',sm:'_1joxg6c1b',md:'_1joxg6c1c',lg:'_1joxg6c1d',xl:'_1joxg6c1e'},borderRadius:{initial:'_1joxg6c1f',sm:'_1joxg6c1g',md:'_1joxg6c1h',lg:'_1joxg6c1i',xl:'_1joxg6c1j'},height:{initial:'_1joxg6c1k',sm:'_1joxg6c1l',md:'_1joxg6c1m',lg:'_1joxg6c1n',xl:'_1joxg6c1o'},objectPosition:{initial:'_1joxg6c1p',sm:'_1joxg6c1q',md:'_1joxg6c1r',lg:'_1joxg6c1s',xl:'_1joxg6c1t'},maxHeight:{initial:'_1joxg6c1u',sm:'_1joxg6c1v',md:'_1joxg6c1w',lg:'_1joxg6c1x',xl:'_1joxg6c1y'},maxWidth:{initial:'_1joxg6c1z',sm:'_1joxg6c20',md:'_1joxg6c21',lg:'_1joxg6c22',xl:'_1joxg6c23'},minHeight:{initial:'_1joxg6c24',sm:'_1joxg6c25',md:'_1joxg6c26',lg:'_1joxg6c27',xl:'_1joxg6c28'},minWidth:{initial:'_1joxg6c29',sm:'_1joxg6c2a',md:'_1joxg6c2b',lg:'_1joxg6c2c',xl:'_1joxg6c2d'},width:{initial:'_1joxg6c2e',sm:'_1joxg6c2f',md:'_1joxg6c2g',lg:'_1joxg6c2h',xl:'_1joxg6c2i'}};
export var heightVars = {initial:'var(--_1joxg6ca)',sm:'var(--_1joxg6cb)',md:'var(--_1joxg6cc)',lg:'var(--_1joxg6cd)',xl:'var(--_1joxg6ce)'};
export var imageStyle = '_1joxg6c19';
export var maxHeightVars = {initial:'var(--_1joxg6ck)',sm:'var(--_1joxg6cl)',md:'var(--_1joxg6cm)',lg:'var(--_1joxg6cn)',xl:'var(--_1joxg6co)'};
export var maxWidthVars = {initial:'var(--_1joxg6cp)',sm:'var(--_1joxg6cq)',md:'var(--_1joxg6cr)',lg:'var(--_1joxg6cs)',xl:'var(--_1joxg6ct)'};
export var minHeightVars = {initial:'var(--_1joxg6cu)',sm:'var(--_1joxg6cv)',md:'var(--_1joxg6cw)',lg:'var(--_1joxg6cx)',xl:'var(--_1joxg6cy)'};
export var minWidthVars = {initial:'var(--_1joxg6cz)',sm:'var(--_1joxg6c10)',md:'var(--_1joxg6c11)',lg:'var(--_1joxg6c12)',xl:'var(--_1joxg6c13)'};
export var objectPositionVars = {initial:'var(--_1joxg6cf)',sm:'var(--_1joxg6cg)',md:'var(--_1joxg6ch)',lg:'var(--_1joxg6ci)',xl:'var(--_1joxg6cj)'};
export var widthVars = {initial:'var(--_1joxg6c14)',sm:'var(--_1joxg6c15)',md:'var(--_1joxg6c16)',lg:'var(--_1joxg6c17)',xl:'var(--_1joxg6c18)'};