import { forwardRef, SelectHTMLAttributes, OptionHTMLAttributes, OptgroupHTMLAttributes, useRef } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { ChevronDownIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { InputBaseProps, inputStyle } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { Stack } from '../Stack/Stack';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';
/**
 * Native input element attributes picked from SelectHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputSelectHTMLAttributes = Pick<
  SelectHTMLAttributes<HTMLSelectElement>,
  'autoComplete' | 'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus'
>;

type InputOptionHTMLAttributes = Omit<OptionHTMLAttributes<HTMLOptionElement>, 'selected'> & {
  label: string; // override label to be required
  value: string; // override value to be required
};

type InputOptgroupHTMLAttributes = OptgroupHTMLAttributes<HTMLOptGroupElement> & {
  label: string;
};

type OptgroupProps = InputOptgroupHTMLAttributes & {
  options: InputOptionHTMLAttributes[];
};

type InputSelectProps = Omit<InputBaseProps, 'isReadOnly'> & {
  options: Array<InputOptionHTMLAttributes | OptgroupProps>;
  defaultValue?: string;
};

const StyledSelect = styled('select', inputStyle, {
  /* for Firefox */
  '-moz-appearance': 'none',
  /* for Safari, Chrome, Opera */
  '-webkit-appearance': 'none',
  paddingRight: '$12',
});

export const RelativeContainer = styled('div', {
  position: 'relative',
});

export const IconContainer = styled(Stack, {
  pointerEvents: 'none',
  position: 'absolute',
  right: '0',
  top: 0,
  height: '100%',
  paddingRight: '$3',
});

/**
 * When the type is filter, we don't want a placeholder OR the isOptional prop. With type filter the
 * input is automatically optional.
 */
type TypeFilter = {
  type: 'filter';
  placeholder?: never;
  isOptional?: never;
  visuallyHideLabel?: boolean;
  /** Section of the page that will be affected by the filter. Make sure that it is recognizable by screenreaders, for example with semantic HTML. */
  filteredElement: string;
};

type SelectOptionalOrFilterProps =
  /**
   * When the type is filter, we need value OR defaultValue.
   */
  | (TypeFilter & {
      value: string;
    })
  | (TypeFilter & {
      defaultValue: string;
    })
  /**
   * The `placeholder` attribute is required when the input is required. See note 2 for more information.
   */
  | {
      isOptional?: undefined | false;
      placeholder: string;
      type?: never;
      filteredElement?: never;
      visuallyHideLabel?: never;
    }
  | {
      isOptional: true;
      placeholder?: string;
      type?: never;
      filteredElement?: never;
      visuallyHideLabel?: never;
    };

type Props = InputSelectProps & InputSelectHTMLAttributes & SelectOptionalOrFilterProps;

export const InputSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      error,
      filteredElement,
      hint,
      isDisabled,
      isOptional,
      label,
      name,
      options,
      placeholder,
      type,
      value,
      visuallyHideLabel,
      ...rest
    },
    ref,
  ) => {
    const localRef = useRef<HTMLSelectElement>(null);
    const mergedRef = mergeRefs([ref, localRef]);

    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const { willBeUpdated, filterBy } = useI18nTranslations();

    useValidationErrorEvent({ error, name }, localRef);

    const filterPrefix = type === 'filter' ? filterBy : '';

    return (
      <div>
        <Stack gap="2">
          {visuallyHideLabel && type === 'filter' ? (
            <VisuallyHidden>
              <label htmlFor={inputId}>{label}</label>
            </VisuallyHidden>
          ) : (
            <Label htmlFor={inputId} isOptional={isOptional && type !== 'filter'}>
              {`${filterPrefix}${label}`}
              {type === 'filter' && <VisuallyHidden>{`${filteredElement} ${willBeUpdated}`}</VisuallyHidden>}
            </Label>
          )}
          <Stack.Item grow>
            <RelativeContainer>
              <StyledSelect
                aria-describedby={describedBy}
                aria-invalid={error ? true : undefined}
                defaultValue={value === undefined && placeholder ? '' : undefined}
                disabled={isDisabled}
                id={inputId}
                isInvalid={!!error}
                name={name}
                ref={mergedRef}
                /**
                 * The select component is special because, unlike other form elements in Sparky it does use the `required`
                 * attribute. The attribute is used to let the (screen reader) users know the field is invalid, even though an
                 * option (the placeholder) is selected. This can't be done with just `aria-invalid` because uncontrolled
                 * components can't determine if a pristine select input has a default value. Check note 1 for more info.
                 */
                required={isOptional || type === 'filter' ? undefined : true}
                /**
                 * The `value` and the `defaultValue` attributes should not be used at the same time. For controlled
                 * components we use value, so we check if `value` is `undefined` before setting a default value. For
                 * uncontrolled components the value is `undefined`, and the defaultValue is set when a placeholder exists.
                 */
                value={value}
                {...rest}>
                {placeholder ? (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                ) : null}
                {options.map(option => {
                  if ('options' in option) {
                    const { label, options, ...rest } = option;

                    return (
                      <optgroup key={label} label={label} {...rest}>
                        {options.map(({ value, label, ...rest }) => (
                          <option data-label={label} key={value} value={value} {...rest}>
                            {label}
                          </option>
                        ))}
                      </optgroup>
                    );
                  } else {
                    const { value, label, ...rest } = option;

                    return (
                      <option data-label={label} key={value} value={value} {...rest}>
                        {label}
                      </option>
                    );
                  }
                })}
              </StyledSelect>
              <IconContainer alignY="center">
                <ChevronDownIcon color="iconSecondary" />
              </IconContainer>
            </RelativeContainer>
            {error ? <Error id={errorId}>{error}</Error> : null}
          </Stack.Item>
          {hint ? <Hint id={hintId}>{hint}</Hint> : null}
        </Stack>
      </div>
    );
  },
);

IconContainer.displayName = 'IconContainer';
RelativeContainer.displayName = 'RelativeContainer';
StyledSelect.displayName = 'styled(select)';
InputSelect.displayName = 'InputSelect';

/**
 * Notes
 *
 * 1) If a select element contains a placeholder label option, the user agent is expected to render that option in a
 *    manner that conveys that it is a label, rather than a valid option of the control. This can include preventing the
 *    placeholder label option from being explicitly selected by the user. When the placeholder label option's
 *    selectedness is true, the control is expected to be displayed in a fashion that indicates that no valid option is
 *    currently selected.
 *
 *    Source: https://html.spec.whatwg.org/multipage/form-elements.html#the-select-element
 *    More on placeholder: https://ux.stackexchange.com/questions/90642/accessibility-of-disabled-placeholders-in-dropdown
 *
 * 2) If a select element has a required attribute specified, does not have a multiple attribute specified, and has a
 *    display size of 1, then the select element must have a placeholder label option.
 *
 *    Source: https://html.spec.whatwg.org/multipage/form-elements.html#the-select-element
 */
