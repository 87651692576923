import { forwardRef, InputHTMLAttributes, useRef } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { ElectricityIcon, GasIcon, WarmthIcon, RedeliveryIcon, WaterIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Box } from '../Box/Box';
import { InputBaseProps } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

const InputWrapper = styled('div', {
  padding: '$3',
  borderRadius: '$s',
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',

  '&:focus-within': {
    outline: '$outlineInputFocus',
    borderColor: '$borderFocus',
  },

  variants: {
    isInvalid: {
      true: {
        border: '$borderWidths$s solid $formBorderError',
        boxShadow: '$shadowError',
      },
      false: {
        '&:hover': {
          boxShadow: '$shadowHover',
          borderColor: '$formBorderHover',
        },
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: '$opacity50',
        '&:hover': {
          boxShadow: '$none',
          borderColor: '$formBorderDefault',
        },
      },
    },
  },
});

const StyledInput = styled('input', {
  width: '100%',
  fontSize: '$BodyL',
  border: 'none',
  color: '$textPrimary',

  '&::placeholder': {
    color: '$textLowEmphasis',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
});

type UsageType = 'energy' | 'gas' | 'warmth' | 'redelivery' | 'water';
type Unit = 'kWh' | 'Wh' | 'm3' | 'GJ';
type UnitSymbol = 'kWh' | 'Wh' | 'm³' | 'GJ';
type InUnitLabel = 'inKilowattHour' | 'inWattHour' | 'inCubicMetres' | 'inGigajoule';

const unitMap: Record<
  Unit,
  {
    symbol: UnitSymbol;
    inLabel: InUnitLabel;
  }
> = {
  kWh: { symbol: 'kWh', inLabel: 'inKilowattHour' },
  Wh: { symbol: 'Wh', inLabel: 'inWattHour' },
  m3: { symbol: 'm³', inLabel: 'inCubicMetres' },
  GJ: { symbol: 'GJ', inLabel: 'inGigajoule' },
};

const usageTypeIcon: Record<UsageType, JSX.Element> = {
  energy: <ElectricityIcon color="iconElectricity" />,
  gas: <GasIcon color="iconGas" />,
  warmth: <WarmthIcon color="iconHeat" />,
  redelivery: <RedeliveryIcon color="iconSolar" />,
  water: <WaterIcon color="iconCooling" />,
};

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputUsageHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'defaultValue' | 'value' /** used for controlled inputs */ | 'onChange' | 'onBlur' | 'onFocus'
>;

type InputUsageProps = InputBaseProps & {
  /**
   * The type of data the input is used for, takes care of the icon.
   */
  type: UsageType;
  /**
   * Takes care of the label helper and unit suffix.
   */
  unit: Unit;
};

type Props = InputUsageProps & InputUsageHTMLAttributes;

export const InputUsage = forwardRef<HTMLInputElement, Props>(
  ({ error, hint, isDisabled, isOptional, isReadOnly, label, name, type, unit, ...rest }, ref) => {
    const localRef = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs([ref, localRef]);

    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    const i18nOptionalText = useI18nTranslation('optional');
    const i18nInUnitText = useI18nTranslation(unitMap[unit].inLabel);

    useValidationErrorEvent({ error, name }, localRef);

    return (
      <div>
        <label htmlFor={inputId}>
          <Stack gap="2" alignY="center">
            <Text size="BodyS" weight="bold">
              {label}
              <VisuallyHidden>{i18nInUnitText}</VisuallyHidden>
              {isOptional ? <span>{` (${i18nOptionalText})`}</span> : null}
            </Text>
            <InputWrapper isInvalid={!!error} isDisabled={isDisabled}>
              <Stack direction="row" gap="2">
                {usageTypeIcon[type]}
                <Stack.Item grow>
                  <StyledInput
                    aria-describedby={describedBy}
                    aria-invalid={error ? true : undefined}
                    disabled={isDisabled}
                    id={inputId}
                    inputMode="numeric"
                    name={name}
                    readOnly={isReadOnly}
                    ref={mergedRef}
                    type="text"
                    {...rest}
                  />
                </Stack.Item>
                <Text aria-hidden={true} color="textLowEmphasis" weight="bold">
                  {unitMap[unit].symbol}
                </Text>
              </Stack>
            </InputWrapper>
          </Stack>
        </label>
        {error ? <Error id={errorId}>{error}</Error> : null}

        {hint ? (
          <Box paddingTop="2">
            <Hint id={hintId}>{hint}</Hint>
          </Box>
        ) : null}
      </div>
    );
  },
);

InputWrapper.displayName = 'InputWrapper';
StyledInput.displayName = 'styled(input)';
InputUsage.displayName = 'InputUsage';
