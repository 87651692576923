import React from 'react';

import { styled } from '../../stitches.config';
import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

export const StyledRibbon = styled('div', {
  fontWeight: '$bodyBold',
  textAlign: 'center',
  variants: {
    emphasis: {
      high: {
        background: '$ribbonBackgroundHighEmphasis',
        color: '$ribbonColorHighEmphasis',
      },
      medium: {
        background: '$ribbonBackgroundMediumEmphasis',
        color: '$ribbonColorMediumEmphasis',
      },
    },
    size: {
      small: {
        typography: '$bodyXS',
        paddingY: '$2',
      },
      large: {
        typography: '$bodyS',
        paddingY: '$4',
      },
    },
  },
  defaultVariants: {
    size: 'small',
    emphasis: 'high',
  },
});

const stitchesClassName = 'sparky-box';

export const Ribbon = React.forwardRef<HTMLDivElement, RibbonProps>(
  ({ children, as = 'div', className = '', id, ...props }, ref) => {
    const variantProps = extractVariantProps(props);

    return (
      <StyledRibbon as={as} ref={ref} {...variantProps} className={`${stitchesClassName} ${className}`} id={id}>
        {children}
      </StyledRibbon>
    );
  },
);

Ribbon.toString = () => `.${stitchesClassName}`;
StyledRibbon.displayName = 'styled(Ribbon)';
Ribbon.displayName = 'Ribbon';

type RibbonVariants = TransformStitchesToSparky<typeof StyledRibbon>;
type RibbonProps = RibbonVariants & {
  /** The DOM element to render*/
  as?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  className?: never;
  id?: string;
};

export default Ribbon;
