import { mediaQueryKeys } from '../../sparky.config';
import { DynamicVariantProp, Responsive } from '../../types';

/**
 * Calculates the aspect ratio of an image based on the provided aspectRatio, height, and width props.
 * It first checks the given aspectRatio and returns the width and height values from that if set. If not, it derives
 * the aspect ratio from the height and width props. If neither are set, it returns undefined.
 *
 * For both the aspectRatio and height/width props, it first checks if the value is a string and uses that value. If
 * not, it checks if the value is a responsive object and uses the first value found.
 */
const getAspectRatioDimensions = (props: {
  aspectRatio?: DynamicVariantProp<'aspectRatio'>;
  height?: DynamicVariantProp<'height'>;
  width?: DynamicVariantProp<'width'>;
}) => {
  const { aspectRatio: aspectRatioProp, height: heightProp, width: widthProp } = props;

  // Return the width / height from the aspectRatio if it's a string
  if (typeof aspectRatioProp === 'string') {
    const [width, height] = aspectRatioProp.replace(' ', '').split('/');
    return { height, width };
  }

  // AspectRatio property is likely a responsive object, try to find the first value and return that if found
  let foundAspectRatio: string | undefined;
  mediaQueryKeys.some(key => {
    foundAspectRatio = (aspectRatioProp as Responsive<'aspectRatio'>)?.[key];
    return foundAspectRatio;
  });

  if (foundAspectRatio) {
    const [width, height] = foundAspectRatio.replace(' ', '').split('/');
    return { height, width };
  }

  // No aspectRatio value was found, try to find a match in the height and width properties
  if (
    parseInt(heightProp as string) &&
    heightProp !== '100%' &&
    parseInt(widthProp as string) &&
    widthProp !== '100%'
  ) {
    return { height: heightProp as string, width: widthProp as string };
  }

  // Height and width properties are likely responsive objects, try to find the first values and return those if found
  let height, width;

  mediaQueryKeys.some(key => {
    const foundHeight = (heightProp as Responsive<'height'>)?.[key] as string | undefined;
    const foundWidth = (widthProp as Responsive<'width'>)?.[key] as string | undefined;

    if (
      parseInt(foundHeight as string) &&
      foundHeight !== '100%' &&
      parseInt(foundWidth as string) &&
      foundWidth !== '100%'
    ) {
      height = foundHeight;
      width = foundWidth;

      return true;
    }

    return false;
  });

  if (height && width) {
    return { height, width };
  }

  return;
};

export { getAspectRatioDimensions };
