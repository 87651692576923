import React from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { ChangeHandler, useNumberInput, FormatOptions } from '../../hooks/useNumberInput';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { MinusIcon, PlusIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { Grid } from '../Grid/Grid';
import { InputBaseProps, StyledInput } from '../Input/Input';
import { Arrow, Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { getInputMode, InputNumberHTMLAttributes } from '../InputNumber/InputNumber';
import { Stack } from '../Stack/Stack';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

type InputStepperProps = InputBaseProps &
  InputNumberHTMLAttributes & {
    defaultValue?: number;
    min?: number | string;
    max?: number | string;
    step?: number | string;
    onChange?(e: ChangeHandler): void;
    formatOptions?: FormatOptions;
  };

const InputStepperButton = styled('button', {
  minHeight: '$inputMinHeight',
  minWidth: '$inputMinHeight',
  borderRadius: '$s',
  backgroundColor: '$backgroundPrimary',
  border: '$borderWidths$s solid $formBorderDefault',
  cursor: 'pointer',
  color: '$iconSecondary',

  '&:not(:disabled):hover': {
    boxShadow: '$shadowHover',
    borderColor: '$formBorderHover',
    color: '$iconBrand',
  },

  '&:disabled': {
    opacity: '$opacity50',
    cursor: 'not-allowed',
  },
});

/**
 * 🚨 Snowflake alert 🚨
 * InputStepper is the only input component that has an error message with a centered triangle
 */
const ErrorWithCenteredArrow = styled(Error, {
  [`${Arrow}`]: {
    marginLeft: '50%',
    transform: 'translateX(-50%) rotate(45deg)',
  },
});

export const InputStepper = React.forwardRef<HTMLInputElement, InputStepperProps>((props, ref) => {
  const { defaultValue, min, max, step, onChange, onBlur, formatOptions, ...inputProps } = props;
  const { error, isOptional, label, hint, isDisabled, isReadOnly, name, ...rest } = inputProps;

  // Locally use the ref for useNumberInput
  const localRef = React.useRef<HTMLInputElement>(null);
  const mockRef = React.useRef<HTMLInputElement>(null);

  // Control the number input
  const {
    value: hookValue,
    increment,
    decrement,
    onKeyDown,
    onFocus,
    onMouseUp,
    onChange: numberOnChange,
    max: ariaMax,
    min: ariaMin,
    ariaMessage,
    formattedValue,
  } = useNumberInput({ defaultValue, min, max, step, onChange, formatOptions }, mockRef);

  useValidationErrorEvent({ error, name }, localRef);

  // Merge the local ref and forwarded ref so we can forward it *and* use it locally
  const mergedRef = mergeRefs([ref, mockRef]);

  // Generate IDs
  const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });

  // Accessibile translated labels
  const { increase, decrease } = useI18nTranslations();

  return (
    <div>
      <Stack gap="2">
        <Label htmlFor={inputId} isOptional={isOptional}>
          {label}
        </Label>
        <div>
          <Stack direction={'row'} gap="2">
            <InputStepperButton
              disabled={isDisabled}
              tabIndex={-1}
              aria-label={`${decrease} ${label}`}
              aria-controls={inputId}
              type="button"
              onClick={decrement}>
              <MinusIcon color="currentColor" />
            </InputStepperButton>
            <Stack.Item grow>
              <div>
                <VisuallyHidden ariaLive="off">
                  <input
                    tabIndex={-1}
                    aria-hidden="true"
                    ref={mergedRef}
                    name={name}
                    onFocus={() => {
                      if (localRef) localRef.current?.focus();
                    }}
                  />
                </VisuallyHidden>

                <StyledInput
                  name={`${name}-visual`}
                  autoComplete="off"
                  isInvalid={!!error}
                  ref={localRef}
                  id={inputId}
                  type="text"
                  aria-describedby={describedBy}
                  aria-invalid={error ? true : undefined}
                  role="spinbutton"
                  aria-valuemax={ariaMax}
                  aria-valuemin={ariaMin}
                  aria-valuenow={Number(hookValue)}
                  value={formattedValue}
                  onKeyDown={onKeyDown}
                  onChange={numberOnChange}
                  onFocus={onFocus}
                  onMouseUp={onMouseUp}
                  onBlur={({ target, ...blurEventProps }) => {
                    // Manually call the the passed blur with the mocked ref
                    if (mockRef.current && onBlur) {
                      onBlur({ target: mockRef?.current, ...blurEventProps });
                    }
                  }}
                  inputMode={getInputMode(formatOptions)}
                  disabled={isDisabled}
                  readOnly={isReadOnly}
                  {...rest}
                />
                <VisuallyHidden ariaLive="assertive">{ariaMessage}</VisuallyHidden>
              </div>
            </Stack.Item>

            <InputStepperButton
              disabled={isDisabled}
              tabIndex={-1}
              aria-label={`${increase} ${label}`}
              aria-controls={inputId}
              type="button"
              onClick={increment}>
              <PlusIcon color="currentColor" />
            </InputStepperButton>
          </Stack>

          {error ? <ErrorWithCenteredArrow id={errorId}>{error}</ErrorWithCenteredArrow> : null}
        </div>
        {hint && (
          <Grid.Item gridArea="hint">
            <Hint id={hintId}>{hint}</Hint>
          </Grid.Item>
        )}
      </Stack>
    </div>
  );
});

InputStepperButton.displayName = 'InputStepperButton';
ErrorWithCenteredArrow.displayName = 'ErrorWithCenteredArrow';
InputStepper.displayName = 'InputStepper';
