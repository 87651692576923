import { forwardRef, TextareaHTMLAttributes, useRef } from 'react';

import { useInputIds } from '../../hooks/useInputIds';
import { useValidationErrorEvent } from '../../hooks/useValidationErrorEvent';
import { styled } from '../../stitches.config';
import { mergeRefs } from '../../util';
import { InputBaseProps, inputStyle } from '../Input/Input';
import { Error } from '../Input/InputError';
import { Hint } from '../Input/InputHint';
import { Label } from '../Input/InputLabel';
import { Stack } from '../Stack/Stack';

type TextAreaProps = InputBaseProps & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'name'>;

const StyledTextArea = styled('textarea', inputStyle, {
  display: 'block',
});

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, hint, isDisabled, isOptional, label, name, ...rest }, ref) => {
    const localRef = useRef<HTMLTextAreaElement>(null);
    const mergedRef = mergeRefs([ref, localRef]);

    useValidationErrorEvent({ error, name }, localRef);

    const { inputId, describedBy, errorId, hintId } = useInputIds({ error, hint });
    return (
      <Stack gap="2">
        <Label htmlFor={inputId} isOptional={isOptional}>
          {label}
        </Label>
        <Stack.Item grow>
          <StyledTextArea
            aria-describedby={describedBy}
            aria-invalid={error ? true : undefined}
            disabled={isDisabled}
            id={inputId}
            isInvalid={!!error}
            name={name}
            ref={mergedRef}
            {...rest}
          />
          {error ? <Error id={errorId}>{error}</Error> : null}
        </Stack.Item>
        {hint ? <Hint id={hintId}>{hint}</Hint> : null}
      </Stack>
    );
  },
);

StyledTextArea.displayName = 'styled(textarea)';
TextArea.displayName = 'TextArea';
