import React, { Children, FC, ReactNode, useState } from 'react';

import { useFormatter } from '@i18n';
import { styled } from '@stitches/react';
import ReactIs from 'react-is';

import { useI18nTranslations } from '../../providers/i18n';
import type { ImageProps } from '../../types';
import { Box } from '../Box/Box';
import { ButtonOverlay } from '../ButtonOverlay/ButtonOverlay';
import { Grid } from '../Grid/Grid';
import { Heading } from '../Heading/Heading';
import { Image } from '../Image/Image';
import { IndexCircle } from '../IndexCircle/IndexCircle';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';
import { TransitionHeight } from '../Transition/Transition';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';

export interface StepListItemProps extends React.PropsWithChildren {
  description?: ReactNode;
  step?: string;
  image?: Pick<ImageProps, 'alt' | 'src' | 'sources'>;
  amountOfSteps?: number;
  isInteractive?: boolean;
  isActive?: boolean;
  setActiveStep?: (step: string) => void;
}

const DashedLine = styled('span', {
  display: 'block',
  height: '100%',
  width: '$borderWidths$s',
  margin: '0 auto',
  background:
    'repeating-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 5px, rgba(0,0,0,1) 5px, rgba(0,0,0,1) 10px)',
});

const ContentWithDashedPadding = styled(Stack, {
  paddingBottom: '22px',
});

export const RelativeGridItem = styled(Grid.Item, { position: 'relative' });
export const IndexCircleWrapper = styled('div', { position: 'relative', zIndex: 1 });

const StepListItem: FC<React.PropsWithChildren<StepListItemProps>> = ({
  children,
  description = '',
  step,
  image,
  isActive,
  setActiveStep,
  amountOfSteps,
  isInteractive,
}) => {
  const { format } = useFormatter();
  const { stepOf } = useI18nTranslations();

  const Title: React.FC = () => (
    <Heading
      as="h3"
      size={isInteractive && isActive ? 'S' : '3XS'}
      color={isInteractive && !isActive ? 'textLowEmphasis' : 'textPrimary'}
      id={`step-explainer-step-${step}`}>
      <VisuallyHidden>
        {format(`${stepOf}: `, {
          step: step! /* !, as stepList sets these values dynamically */,
          total: amountOfSteps! /* !, as stepList sets these values dynamically */,
        })}
      </VisuallyHidden>
      {children}
    </Heading>
  );

  const StepImage: React.FC = props =>
    image ? (
      <Image
        alt={image.alt}
        src={image.src}
        width="100%"
        maxHeight="100%"
        hasLazyLoad
        sources={image.sources}
        display="block"
        {...props}
      />
    ) : null;

  if (isInteractive) {
    return (
      <>
        <Grid.Item gridColumn="1 / 2" as="li">
          <ButtonOverlay>
            <ButtonOverlay.Button
              onClick={step ? () => setActiveStep?.(step) : undefined}
              aria-label={format('Activeer stap {step} van de {total}', {
                step: step!,
                total: amountOfSteps!,
              })}>
              <IndexCircle emphasis={isActive ? 'high' : 'low'}>{step}</IndexCircle>
            </ButtonOverlay.Button>
          </ButtonOverlay>
          {amountOfSteps && Number(step) < amountOfSteps ? <DashedLine /> : null}
        </Grid.Item>
        <Grid.Item gridColumn="2/-1">
          <ContentWithDashedPadding gap="2">
            <ButtonOverlay>
              <ButtonOverlay.Button
                onClick={
                  step
                    ? () => {
                        setActiveStep?.(step);
                      }
                    : undefined
                }>
                <Title />
              </ButtonOverlay.Button>
            </ButtonOverlay>
            <TransitionHeight in={isActive} hideWhenCollapsed>
              <Grid gridTemplateColumns={{ initial: '', md: '24px repeat(12, 1fr)' }}>
                <Grid.Item gridColumn="1 / 6">
                  <Box paddingY="6">{description}</Box>
                </Grid.Item>
                <Grid.Item order={{ initial: -1, md: 1 }} gridColumn="7 / -1">
                  <StepImage />
                </Grid.Item>
              </Grid>
            </TransitionHeight>
          </ContentWithDashedPadding>
        </Grid.Item>
      </>
    );
  }

  return (
    <>
      <Grid.Item gridColumn="1 / 2" as="li">
        <IndexCircle emphasis="high">{step}</IndexCircle>
        {amountOfSteps && Number(step) < amountOfSteps ? <DashedLine /> : null}
      </Grid.Item>
      <Grid.Item gridColumn={'2/-1'} as="li">
        <Stack gap="2">
          <Title />
          <Stack gap="6">
            <Text>{description}</Text>
            <Stack.Item />
          </Stack>
        </Stack>
      </Grid.Item>
    </>
  );
};

type CompoundStepListItemProps = {
  Item: typeof StepListItem;
};

export const StepList: FC<React.PropsWithChildren<{ variant?: 'default' | 'interactive' }>> &
  CompoundStepListItemProps = ({ variant = 'default', children }) => {
  const arrayChildren = Children.toArray(children);
  const [activeStep, setActiveStep] = useState<number | undefined>(variant === 'interactive' ? 1 : undefined);

  return (
    <Grid
      as="ol"
      gridTemplateColumns="24px repeat(12, 1fr)"
      columnGap={{
        initial: '2',
        md: '3',
        lg: '4',
        xl: '6',
      }}
      rowGap="2">
      {arrayChildren?.map((child, index) => {
        if (ReactIs.isElement(child)) {
          const interactiveProps =
            variant === 'interactive'
              ? {
                  isActive: activeStep && index + 1 === activeStep,
                  setActiveStep,
                }
              : {};

          return React.cloneElement(child, {
            amountOfSteps: arrayChildren.length,
            isInteractive: variant === 'interactive',
            step: index + 1,
            ...interactiveProps,
          });
        }
        return child;
      })}
    </Grid>
  );
};

StepList.Item = StepListItem;
