import React, { FC, useId } from 'react';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import ReactIs from 'react-is';

import { styled } from '../../stitches.config';
import { Stack } from '../Stack/Stack';

const Container = styled(RadioGroupPrimitive.Root, {
  borderRadius: '40px',
  backgroundColor: '$controlsInactive',

  position: 'relative',
  zIndex: '0',
});

const Label = styled('label', {
  padding: '$3 0',

  borderRadius: '40px',
  position: 'relative',

  fontWeight: '$bodyBold',
  typography: '$bodyXS',

  flexBasis: '100%',

  cursor: 'pointer',
  textAlign: 'center',
});

export const Item = styled(RadioGroupPrimitive.Item, {
  unset: 'all',
  height: '0',
  width: '0',
  padding: '0',
  borderWidth: '0',
  zIndex: '1',

  '&::before': {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '40px',
    content: '',
    position: 'absolute',
    outline: '$outlineFocus',
    outlineOffset: '-$borderWidths$m',
    cursor: 'pointer',
    opacity: 0,
  },

  '&:hover': {
    '&::before': {
      opacity: 1,
    },
  },

  '&:focus-visible': {
    outline: 'none',
    '&::before': {
      opacity: 1,
    },
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: 'none',
      '&::before': {
        opacity: 1,
      },
    },
  },
});

export const Indicator = styled('div', {
  height: '100%',
  left: '0',

  borderRadius: '40px',
  boxShadow: 'inset 0 0 0 $borderWidths$m $colors$formBorderDefault',
  borderColor: '$formBorderDefault',
  backgroundColor: '$controlsKnob',

  transition: '$easeMedium',

  position: 'absolute',
  zIndex: '-1',
});

// TODO: Replace comopnent props typeof with RadioGroupItemProps from radix
type TabSwitchItemProps = React.ComponentProps<typeof RadioGroupPrimitive.Item> & {
  describedBy?: string;
};

const TabSwitchItem: FC<React.PropsWithChildren<TabSwitchItemProps>> = ({ children, value, describedBy }) => {
  const id = useId();

  return (
    <Label htmlFor={id}>
      <Item id={id} value={value} aria-describedby={describedBy} />
      {children}
    </Label>
  );
};

const TabSwitchComponent = React.forwardRef<HTMLDivElement, React.PropsWithChildren<TabSwitchProps>>(
  ({ children, name, value, onValueChange, defaultValue, describedBy, isOptional }, ref) => {
    const items = React.Children.toArray(children);
    const itemsCount = items.length;

    const indicatorPosition = items.findIndex(child => !!(React.isValidElement(child) && value === child.props.value));
    const isIndicatorVisible = !!items[indicatorPosition];

    const augmentChild = (child: React.ReactNode): React.ReactNode => {
      if (ReactIs.isElement(child)) {
        if (process.env.NODE_ENV === 'development') {
          if (child.type !== TabSwitchItem) {
            // eslint-disable-next-line no-console
            console.error(
              'Error: The `<TabSwitch>` component contains `children` that are invalid. `<TabSwitch>` may only contain `<TabSwitch.Item />`.',
            );
          }
        }

        return React.cloneElement(child, {
          describedBy,
        });
      }

      return child;
    };

    return (
      <Container
        ref={ref}
        name={name}
        onValueChange={onValueChange}
        value={value}
        defaultValue={defaultValue}
        required={!isOptional}
        loop>
        <Stack direction="row" alignX="center">
          <Indicator
            css={{
              width: `calc(100% / ${itemsCount})`,
              transform: `translateX(calc(100% * ${indicatorPosition}))`,
              display: `${isIndicatorVisible ? 'block' : 'none'}`,
            }}
          />

          {React.Children.map(children, augmentChild)}
        </Stack>
      </Container>
    );
  },
);

type RadioGroupProps = Pick<
  React.ComponentProps<typeof RadioGroupPrimitive.Root>,
  'ref' | 'defaultValue' | 'onValueChange'
>;

type TabSwitchProps = RadioGroupProps & {
  name: string;
  value: string;
  isOptional?: boolean;
  describedBy?: string;
};

TabSwitchItem.displayName = 'TabSwitch.Item';
Container.displayName = 'Container';
Label.displayName = 'Label';
Item.displayName = 'Item';
Indicator.displayName = 'Indicator';
TabSwitchComponent.displayName = 'TabSwitch';

// Compound components with forwardRef cause issues: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export const TabSwitch = Object.assign({}, TabSwitchComponent, { Item: TabSwitchItem });
