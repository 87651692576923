import React, { forwardRef, SyntheticEvent } from 'react';

import reactToText from 'react-to-text';

import { ChevronRightIcon, ExternalIcon , Icon } from '../../icons';
import { styled } from '../../stitches.config';
import { LinkColors, TextColors } from '../../types';
import { extractDataProps } from '../../util/attributes';
import { extractVariantProps, extractCssProps } from '../../util/css/stitches';
import { Text } from '../Text/Text';

export const StyledLink = styled('a', {
  cursor: 'pointer',
  display: 'inline',
  textDecoration: 'none',
  textDecorationSkipInk: 'none',

  'button&': {
    background: 'none',
    border: 0,
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    padding: 0,
    textAlign: 'left',
  },

  [`& ${Icon}`]: {
    fill: 'currentColor',
  },
});

export const StyledContainer = styled(Text, {
  display: 'inline',

  variants: {
    hasLeftIcon: {
      true: { marginLeft: '$1' },
    },
    hasRightIcon: {
      true: { marginRight: '$1' },
    },
  },
});

export const StyledText = styled('span', {
  // We want the underline below the text and not below possible icons left and right of the text.
  backgroundImage: 'linear-gradient(currentColor, currentColor)',
  backgroundPosition: 'left bottom',
  backgroundSize: '0 2px',
  backgroundRepeat: 'no-repeat',
  paddingBottom: '$1',
  transition: 'background-size $easeQuick',
});

export const StyledTextLink = styled(StyledLink, {
  [`&:hover ${StyledText}`]: {
    backgroundSize: '100% 2px', // show the underline
  },

  '&:focus-visible': {
    outline: '$outlineFocus',
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: '$outlineFocus',
    },
  },

  variants: {
    emphasis: {
      low: {
        color: 'inherit',

        [`& ${StyledText}`]: {
          backgroundPositionX: 'right',
          backgroundPositionY: 'calc(100% - 3px)',
          backgroundSize: '100% 1px',
        },

        [`&:hover ${StyledText}`]: {
          backgroundSize: '0 1px', // hide the underline
        },
      },
      medium: {
        color: '$colors$linkPrimary',
        [`& ${StyledText}`]: { fontWeight: '$bodyBold' },
      },
      high: {
        alignItems: 'center',
        color: '$colors$linkBrand',
        display: 'flex',
        [`& ${StyledText}`]: { fontWeight: '$bodyBold' },
      },
    },
  },
  defaultVariants: {
    emphasis: 'low',
  },
});

const StyledChevronContainer = styled('span', {
  alignSelf: 'flex-start',
});

const stitchesClassName = 'sparky-text-link';

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  (
    {
      ariaLabel,
      children,
      className = '',
      color,
      emphasis = 'low',
      href,
      leftIcon,
      onClick,
      rightIcon,
      target = '',
      type = 'button',
      ...rest
    },
    ref,
  ) => {
    const dataSet = extractDataProps(rest);
    const variantProps = extractVariantProps({ emphasis });

    const baseProps = { className: stitchesClassName, onClick };
    const componentProps = href
      ? { ...baseProps, href, ...(target ? { target } : null), rel: target === '_blank' ? 'noreferrer' : '' }
      : { ...baseProps, as: 'button', type };

    const isHighEmphasis = emphasis === 'high';
    const isExternalLink = target === '_blank';
    const passedColor = color ? extractCssProps({ color }) : undefined;

    return (
      <StyledTextLink
        {...variantProps}
        {...componentProps}
        {...dataSet}
        aria-label={ariaLabel}
        className={`${stitchesClassName} ${className}`}
        css={passedColor}
        data-label={ariaLabel ?? reactToText(children)}
        href={href}
        onClick={onClick}
        ref={ref}>
        {isHighEmphasis ? (
          <StyledChevronContainer>
            <ChevronRightIcon color="currentColor" />
          </StyledChevronContainer>
        ) : (
          leftIcon
        )}
        <StyledContainer hasLeftIcon={!!leftIcon} hasRightIcon={!!rightIcon || isExternalLink}>
          <StyledText>{children}</StyledText>
        </StyledContainer>
        {isExternalLink ? <ExternalIcon color="currentColor" size="small" verticalAlign="text-bottom" /> : rightIcon}
      </StyledTextLink>
    );
  },
);

TextLink.toString = () => `.${stitchesClassName}`;

StyledContainer.displayName = 'styled(StyledContainer)';
StyledText.displayName = 'styled(Text)';
StyledTextLink.displayName = 'styled(TextLink)';
TextLink.displayName = 'TextLink';

type TextLinkButtonProps = {
  href?: never;
  target?: never;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

type TextLinkAnchorProps = {
  href?: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  type?: never;
};

type EmphasisLowType = {
  emphasis?: 'low';
  /** For low emphasis, link can be colored the same as text */
  color?: keyof TextColors;
};

type EmphasisMediumType = {
  emphasis: 'medium';
  /** For medium emphasis, only default color is allowed */
  color?: never;
};

type EmphasisHighType = {
  emphasis: 'high';
  /** For high emphasis, there are specific color options */
  color?: keyof Pick<LinkColors, 'linkBrand' | 'linkPrimary' | 'linkInverted'>;
};

type EmphasisProps = EmphasisLowType | EmphasisMediumType | EmphasisHighType;

// emphasis low, color any
// emphasis medium, no color
// emphasis high, link colors specifically

export type TextLinkProps = {
  onClick?(e: SyntheticEvent): void;
  /** Text shown inside the link. */
  children: React.ReactNode;
  /** Icon shown to the left of the text. */
  leftIcon?: JSX.Element;
  /** Icon shown to the right of the text. */
  rightIcon?: JSX.Element;
  className?: never;
  /** Label that will become the name of the link for a11y toolings and tracking */
  ariaLabel?: string;
} & (TextLinkAnchorProps | TextLinkButtonProps) &
  EmphasisProps;
