import React, { useState, useId, forwardRef, useRef, createContext, useContext, ReactElement, ReactNode } from 'react';

import { delve } from '@common/delve';

import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
import { ChevronUpIcon, Icon } from '../../icons';
import { styled } from '../../stitches.config';

export const StyledCell = styled('td', {
  padding: '$3',
  background: 'inherit',
  textAlign: 'left',
  borderTop: '$borderWidths$s solid $borderDividerLowEmphasis',
  'th&': {
    fontWeight: '$bodyBold',
    whiteSpace: 'nowrap',
  },
  variants: {
    hasRightBorder: {
      true: {
        borderRight: '$borderWidths$s solid $borderDividerLowEmphasis',
      },
    },
    isStickyLeft: {
      true: {
        position: 'sticky',
        left: 0,
      },
    },
    isStickyTop: {
      true: {
        position: 'sticky',
        top: 0,
      },
    },
    isTwoColums: {
      true: {
        textAlign: 'right',
        paddingRight: '$10',
      },
    },
  },
});

StyledCell.displayName = 'styled(Cell)';

const StyledHeader = styled('thead', {
  background: '$backgroundTertiary',
});

StyledHeader.displayName = 'styled(thead)';

type HeaderProps = { children: ReactElement[] };

const Header = forwardRef<HTMLTableSectionElement, HeaderProps>(({ children }, ref) => {
  const { hasLeftLegend } = useTableContext();

  return (
    <StyledHeader ref={ref}>
      <StyledRow>
        {hasLeftLegend ? <StyledCell isStickyLeft isStickyTop css={{ zIndex: 2 }} /> : null}
        {React.Children.map(children, (child, i) => (
          <StyledCell
            isStickyTop
            as="th"
            scope="col"
            css={{ zIndex: 1 }}
            isTwoColums={children.length === 2 && i === 1}>
            {child}
          </StyledCell>
        ))}
      </StyledRow>
    </StyledHeader>
  );
});

const StyledTableBody = styled('tbody', {
  background: '$backgroundPrimary',
  '&[data-state=closed]': {
    /* Because `visibility: collapse` does not behave consistently accross browsers,
    we replicate the behavior of collapsing the height of the rows while keeping column width. */
    [`& > :not(:first-child)`]: {
      //Collapse the row
      visibility: 'hidden',
      lineHeight: 0,
      height: 0,
      overflow: 'hidden',
      [`& *`]: {
        //Remove padding and borders of table cells and anything nested in them
        paddingY: 0,
        marginY: 0,
        lineHeight: 0,
        height: 0,
        borderTop: 'none',
        borderBottom: 'none',
        overflow: 'hidden',
      },
    },
    [`& > tr > th > div > span > ${Icon}`]: {
      transform: 'rotate(180deg)',
    },
  },
});

StyledTableBody.displayName = 'styled(tbody)';

const StyledSectionHeading = styled('tr', {
  background: '$backgroundSecondary',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
        outline: 'none',
        '&:focus-visible': {
          outline: '$outlineFocus',
        },
        '@supports not selector(:focus-visible)': {
          '&:focus': {
            outline: '$outlineFocus',
          },
        },
      },
    },
  },
});

StyledSectionHeading.displayName = 'styled(sectionHeading)';

export const Indicator = styled('span', {
  position: 'absolute',
  right: '$3',
  [`& ${Icon}`]: {
    '@safeMotion': {
      transition: '$easeMedium',
    },
  },
});

const StickyTableContent = styled('div', {
  position: 'sticky',
  left: 0,
  padding: '$3',
});

const RelativeTableCell = styled(StyledCell, {
  position: 'relative',
  padding: 0,
});

type SectionProps = {
  title: string;
  children: ReactNode;
} & StateProps;

type StateProps =
  | {
      isCollapsible?: boolean;
      isOpen?: never;
      setOpen?: never;
    }
  | {
      isCollapsible: true;
      /** Controlled state of the modal */
      isOpen: boolean;
      /** State setter for the controlled state. Required in order to use built-in controls. */
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };

const Section = forwardRef<HTMLTableSectionElement, SectionProps>(
  ({ children, title, isCollapsible, isOpen: isOpenPassed, setOpen: setOpenPassed }, ref) => {
    const [isOpen, setOpen] = useState(true);
    const { width } = useTableContext();

    const isExpanded = isOpenPassed !== undefined ? isOpenPassed : isOpen;

    const clickHandler = () => {
      if (isOpenPassed !== undefined) {
        setOpenPassed(!isOpenPassed);
      } else {
        setOpen(!isOpen);
      }
    };

    const sectionId = useId();

    const keyHandler = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        if (isOpenPassed !== undefined) {
          setOpenPassed(!isOpenPassed);
        } else {
          setOpen(!isOpen);
        }
      }
    };

    if (!isCollapsible) {
      return (
        <StyledTableBody ref={ref}>
          <StyledSectionHeading>
            <RelativeTableCell as="th" scope="rowgroup" colSpan={100}>
              <StickyTableContent css={{ width: `${width}px` }}>{title} </StickyTableContent>
            </RelativeTableCell>
          </StyledSectionHeading>
          {children}
        </StyledTableBody>
      );
    }

    return (
      <StyledTableBody id={sectionId} data-state={isExpanded ? 'open' : 'closed'} ref={ref}>
        <StyledSectionHeading
          clickable
          onKeyDown={keyHandler}
          onClick={clickHandler}
          role="button"
          tabIndex={0}
          aria-controls={sectionId}
          aria-expanded={isExpanded}>
          <RelativeTableCell scope="rowgroup" as="th" colSpan={100}>
            <StickyTableContent css={{ width: `${width}px` }}>
              {title}
              <Indicator>
                <ChevronUpIcon color="iconPrimary" />
              </Indicator>
            </StickyTableContent>
          </RelativeTableCell>
        </StyledSectionHeading>
        {children}
      </StyledTableBody>
    );
  },
);

const StyledRow = styled('tr', {
  background: 'inherit',
  '&:first-of-type': {
    [`& > ${StyledCell}`]: {
      borderTop: 'none',
    },
  },
  variants: {
    isFooter: {
      true: {
        [`& > ${StyledCell}`]: {
          borderTop: '$borderWidths$s solid $borderDividerHighEmphasis',
        },
        fontWeight: '$bodyBold',
      },
    },
  },
});

StyledRow.displayName = 'styled(tr)';

type RowProps = {
  isFooter?: boolean;
  children: ReactElement[];
};

const Row = forwardRef<HTMLTableRowElement, RowProps>(({ children, isFooter }, ref) => {
  const { hasLeftLegend } = useTableContext();
  return (
    <StyledRow isFooter={isFooter} ref={ref}>
      {React.Children.map(children, (child, i) => {
        if (hasLeftLegend && i === 0) {
          return (
            <StyledCell as="th" scope="row" hasRightBorder isStickyLeft>
              {child}
            </StyledCell>
          );
        }
        return <StyledCell isTwoColums={children.length === 2 && i === 1}>{child}</StyledCell>;
      })}
    </StyledRow>
  );
});

type TableContextType = {
  hasLeftLegend: boolean;
  width: number;
};

const TableContext = createContext<TableContextType>({
  hasLeftLegend: false,
  width: 0,
});

const useTableContext = (): TableContextType => useContext(TableContext);

const StyledWrapper = styled('div', {
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',
  borderRadius: '$m',
  border: '$borderWidths$s solid $borderDividerLowEmphasis',
  position: 'relative',
});
StyledWrapper.displayName = 'styled(tableWrapper)';

const StyledTable = styled('table', {
  width: '100%',
  borderSpacing: '0',
  background: '$backgroundPrimary',
});
StyledTable.displayName = 'styled(table)';

const stitchesClassName = 'sparky-table';

const StyledCaption = styled('caption', {
  background: '$backgroundTertiary',
  padding: '$3',
  textAlign: 'left',
  fontWeight: '$bodyBold',
});

type TableProps = {
  children: ReactNode;
  /** Makes the first element in each row a header and also sticky. */
  hasLeftLegend?: boolean;
  /** Title that will become the caption of the table. Do not use in conjunction with a header. */
  title?: string;
  className?: never;
};

const TableComponent = forwardRef<HTMLTableElement, TableProps>(
  ({ children, className = '', hasLeftLegend, title }, ref) => {
    const [width, setWidth] = useState(0);
    const rowChildren: React.ReactElement[] = [];
    const nonRowChildren = React.Children.map(children, child => {
      if (React.isValidElement(child) && delve(child, 'type.displayName') === 'Table.Row') {
        rowChildren.push(child);
      } else {
        return child;
      }
    });

    const wrapperRef = useRef<HTMLTableElement>(null);
    useIsomorphicLayoutEffect(() => {
      const updateWidth = () => {
        if (wrapperRef?.current) {
          setWidth(wrapperRef.current.clientWidth);
        }
      };
      updateWidth();

      window.addEventListener('resize', updateWidth);

      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }, []);

    return (
      <TableContext.Provider value={{ hasLeftLegend: !!hasLeftLegend, width }}>
        <StyledWrapper ref={wrapperRef}>
          <StyledTable ref={ref} className={`${stitchesClassName} ${className}`}>
            {title && <StyledCaption>{title}</StyledCaption>}
            {nonRowChildren}
            {rowChildren.length ? <StyledTableBody>{rowChildren}</StyledTableBody> : null}
          </StyledTable>
        </StyledWrapper>
      </TableContext.Provider>
    );
  },
);

// Compound components with forwardRef cause issues:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export const Table = Object.assign({}, TableComponent, { Header, Section, Row });

TableComponent.displayName = 'Table';
Table.Header.displayName = 'Table.Header';
Table.Section.displayName = 'Table.Section';
Table.Row.displayName = 'Table.Row';
