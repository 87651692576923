 
import React from 'react';

import logger from '@common/log';
import { ComponentRendering } from '@sitecore/types/lib';

type Props = {
  rendering: ComponentRendering;
};

function renderError(componentName: string) {
  return (props: Props): ReturnType<typeof React.createElement> => {
    logger.error('OCiLDI', `Rendering for missing component ${componentName}`, props);
    return React.createElement(
      'div',
      {
        style: { border: '1px inset red' },
      },
      `Missing component: ${componentName}`,
    );
  };
}

export default renderError;
