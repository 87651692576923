import { FC } from 'react';

import { I18nProvider, useTranslation } from '@i18n';

import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { IconButton } from '../IconButton/IconButton';
import { Stack } from '../Stack/Stack';
import { TextLink } from '../TextLink/TextLink';

type CarouselIndicatorProps = {
  amount: number;
  activeItem?: number;
  prevLabel?: string;
  nextLabel?: string;
  hideArrows?: boolean;
  variant?: 'bar' | 'dot';
  visibleItems: number;
  onNavigationClick(index: number): void;
};

const Container = styled('div', {
  width: 'calc(100%/3)',
  paddingY: '$2',
  alignSelf: 'center',
});

const DotIndicator = styled('button', {
  display: 'block',
  height: '8px',
  width: '8px',
  padding: '0',
  borderRadius: '50%',
  border: '1px solid $backgroundDark',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  variants: {
    isActive: {
      true: {
        backgroundColor: '$backgroundDark',
      },
    },
  },
});

const Bar = styled('div', {
  borderRadius: '$m',
  border: '1px solid $backgroundDark',
  width: '100%',
  height: '8px',
  overflow: 'hidden',
});

const BarIndicator = styled('span', {
  borderRadius: '$m',
  height: '100%',
  backgroundColor: '$backgroundDark',
  display: 'block',
  transition: 'margin-left .1s ease-in-out',
});

export const CarouselIndicator: FC<CarouselIndicatorProps> = props => {
  return (
    <I18nProvider dictionary={locale => import(`./content/${locale}.json`)}>
      <CarouselIndicatorComponent {...props} />
    </I18nProvider>
  );
};

export const CarouselIndicatorComponent: FC<CarouselIndicatorProps> = ({
  activeItem,
  amount,
  prevLabel,
  nextLabel,
  hideArrows,
  variant = 'bar',
  visibleItems,
  onNavigationClick,
}) => {
  const { t } = useTranslation();

  const hasActiveItem = typeof activeItem !== 'undefined';
  const { next: nextA11yLabel, previous: previousA11yLabel } = useI18nTranslations();
  const itemWidth = 100 / amount;

  const onPrevClick = () => {
    if (!hasActiveItem) return;

    let previousItem = activeItem - 1;

    if (previousItem < 0) {
      previousItem = amount - 1;
    }

    onNavigationClick(previousItem);
  };

  const onNextClick = () => {
    if (!hasActiveItem) return;

    let nextItem = activeItem + 1;

    if (nextItem + visibleItems > amount) {
      nextItem = 0;
    }

    onNavigationClick(nextItem);
  };

  return (
    <Container>
      <Stack gap="6" alignX="center" direction="row" alignY="center">
        <NavButton
          hideArrows={hideArrows}
          label={prevLabel}
          a11yLabel={previousA11yLabel}
          icon={<ChevronLeftIcon />}
          onClick={onPrevClick}
        />
        {variant === 'dot' ? (
          <Stack direction="row" gap="2" alignY="center" alignX="center">
            {Array.from({ length: amount }).map((_item, index) => (
              <DotIndicator
                aria-label={t('slideIndicator', { currentSlide: index + 1, totalSlides: amount })}
                isActive={activeItem === index}
                key={index}
                onClick={() => {
                  onNavigationClick(index);
                }}
                type="button"
              />
            ))}
          </Stack>
        ) : (
          <Bar>
            <BarIndicator
              css={{
                width: `${visibleItems * itemWidth}%`,
                marginLeft: hasActiveItem ? `${activeItem * itemWidth}%` : undefined,
              }}
            />
          </Bar>
        )}
        <NavButton
          hideArrows={hideArrows}
          label={nextLabel}
          a11yLabel={nextA11yLabel}
          icon={<ChevronRightIcon />}
          onClick={onNextClick}
        />
      </Stack>
    </Container>
  );
};

interface NavButtonProps {
  hideArrows?: boolean;
  label?: string;
  a11yLabel: string;
  icon: JSX.Element;
  onClick(): void;
}

const NavButton: FC<NavButtonProps> = ({ hideArrows, label, a11yLabel, icon, onClick }) => {
  if (hideArrows) return null;

  return label ? (
    <TextLink emphasis="medium" type="button" onClick={onClick}>
      {label}
    </TextLink>
  ) : (
    <IconButton label={a11yLabel} onClick={onClick}>
      {icon}
    </IconButton>
  );
};
