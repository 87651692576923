import { FC, createContext, useContext, useEffect, useState } from 'react';

import { styled } from '../../../stitches.config';
import { sparkyButtonClassName } from '../../Button/Button';
import { Card } from '../../Card/Card';
import { Image as SparkyImage } from '../../Image/Image';
import Ribbon from '../../Ribbon/Ribbon';
import { RadioBase } from '../RadioBase';
import { useRadioGroup } from '../RadioGroup/RadioGroup';
import { RadioIndicator } from '../RadioIndicator';

const StyledRadio = styled(RadioBase, {
  '&::before': {
    content: '',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '$m',
  },

  '&[data-state="checked"]': {
    '&::before': {
      border: '$borderWidths$m solid $borderSelected',
    },
  },

  '&:focus-visible': {
    outline: 'none',

    '&::before': {
      outline: '$outlineFocus',
    },
  },

  '@supports not selector(:focus-visible)': {
    '&:focus': {
      outline: 'none',

      '&::before': {
        outline: '$outlineFocus',
      },
    },
  },

  '&:hover': {
    '&::before': {
      boxShadow: '$shadowHover',
    },
  },
});

/**
 * Uses the same technique as ButtonOverlay and LinkOverlay components. The Indicator component is the interactive
 * item that fills the relative positioned Card.
 */

const CardOverlay = styled('div', Card, {
  overflow: 'hidden',
  borderRadius: 'inherit',

  '& a[href], button:not(.radiocard-button):not(.overlay-button):not(.overlay-link)': {
    position: 'relative',
    zIndex: 1,

    /**
     * Interactive elements nested inside a clickable area need a height of at least 44px to be accessible
     */
    [`&:not(.${sparkyButtonClassName})::before`]: {
      content: '',
      width: '100%',
      minHeight: '$targetMinHeight',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
});

const cardOverlayClassname = 'sparky-cardOverlay';
CardOverlay.toString = () => `.${cardOverlayClassname}`;

const Wrapper = styled(Card, {
  position: 'relative',
  boxShadow: 'none',
});

type RadioCardContextValue = {
  value: string;
  labelId?: string;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
};

const RadioCardContext = createContext<RadioCardContextValue | undefined>(undefined);

const useRadioCard = () => {
  const context = useContext(RadioCardContext);

  if (context === undefined) {
    throw new Error(
      `useRadioCard must be used within a RadioCardContext.Provider. Make sure to place the Indicator component inside a RadioCard.`,
    );
  }

  return context;
};

const getCardLabel = (labelId: string | undefined, ariaLabelledby: string | undefined) => {
  if (labelId) {
    return document.getElementById(`${labelId}`)?.textContent ?? '';
  }

  if (ariaLabelledby) {
    return document.getElementById(`${ariaLabelledby.split(' ')[0]}`)?.textContent ?? '';
  }

  return '';
};

type ImageProps = {
  alt: string;
  src: string;
};

const Image: React.FC<ImageProps> = ({ ...props }) => (
  <SparkyImage {...props} aspectRatio="16/9" objectFit="cover" width="100%"></SparkyImage>
);

const Indicator: React.FC = () => {
  const [dataLabel, setDataLabel] = useState('');
  const { value, labelId, ariaLabelledby, ariaDescribedby } = useRadioCard();

  useEffect(() => {
    setDataLabel(getCardLabel(labelId, ariaLabelledby));
  }, [ariaLabelledby, labelId, setDataLabel]);

  return (
    <StyledRadio
      aria-describedby={ariaDescribedby}
      aria-labelledby={ariaLabelledby}
      data-label={dataLabel}
      className="radiocard-button"
      id={labelId}
      value={value}>
      <RadioIndicator />
    </StyledRadio>
  );
};

type RadioCardLabelProps =
  | {
      labelId: string;
      ariaLabelledby?: never;
    }
  | {
      labelId?: never;
      ariaLabelledby: string;
    };

type RadioCardProps = {
  children: React.ReactNode;
  value: string;
  className?: never;
} & RadioCardLabelProps;

type RadioCardCompoundCardProps = {
  Indicator: typeof Indicator;
  Ribbon: typeof Ribbon;
  Image: typeof Image;
};

export const RadioCard: FC<RadioCardProps> & RadioCardCompoundCardProps = ({
  children,
  value,
  labelId,
  ariaLabelledby,
  className = '',
}) => {
  const { ariaDescribedby } = useRadioGroup();
  return (
    <RadioCardContext.Provider value={{ value, labelId, ariaLabelledby, ariaDescribedby }}>
      <Wrapper>
        <CardOverlay className={`${cardOverlayClassname} ${className}`}>{children}</CardOverlay>
      </Wrapper>
    </RadioCardContext.Provider>
  );
};

RadioCard.Indicator = Indicator;
RadioCard.Indicator.displayName = 'RadioCard.Indicator';

RadioCard.Ribbon = Ribbon;
RadioCard.Ribbon.displayName = 'RadioCard.Ribbon';

RadioCard.Image = Image;
RadioCard.Image.displayName = 'RadioCard.Image';

StyledRadio.displayName = 'styled(RadioBase)';
CardOverlay.displayName = 'CardOverlay';
