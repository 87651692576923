import { forwardRef, InputHTMLAttributes } from 'react';

import { Input, InputBaseProps } from '../Input/Input';

/**
 * Native input element attributes picked from InputHTMLAttributes. More info on what attributes
 * are available: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
 */
type InputTextHTMLAttributes = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'autoComplete'
  | 'placeholder'
  | 'defaultValue'
  | 'value' /** used for controlled inputs */
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'maxLength'
>;

type InputTextProps = InputBaseProps & InputTextHTMLAttributes;

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({ label, hint, error, isOptional, isDisabled, isReadOnly, ...rest }, ref) => {
    return (
      <Input
        ref={ref}
        label={label}
        hint={hint}
        error={error}
        isOptional={isOptional}
        type="text"
        disabled={isDisabled}
        readOnly={isReadOnly}
        {...rest}
      />
    );
  },
);
