import { assignInlineVars } from '@vanilla-extract/dynamic';

import type { CSSProperty, FullResponsive, MediaQueryKeys, OptionalMediaQuery, Responsive } from '../../../types';

export type InlineVarsObject = {
  [K in MediaQueryKeys]: `var(--${string})` | `var(--${string}, ${string | number})`;
};

function isResponsive(value: unknown): value is Responsive<string | undefined> {
  return typeof value === 'object' && value !== null;
}

/**
 * Extracts applicable dynamic styles (CSS classes and accompanying inline CSS variables) for the components' given
 * properties. It checks whether the given properties contain (responsive) values and if so, extracts the corresponding
 * CSS classes and inline CSS variables in order to apply them to the component.
 *
 * Using this utility, you can apply dynamic styles to components based on the properties that are only known at
 * runtime.
 *
 * @example
 * const { classNames, cssVars } = extractDynamicStyles({
 *   height: [
 *     {
 *       initial: 'height-class-initial',
 *       sm: 'height-class-sm',
 *       md: 'height-class-md',
 *       lg: 'height-class-lg',
 *       xl: 'height-class-xl',
 *     },
 *     {
 *       initial: 'height-var-initial',
 *       sm: 'height-var-sm',
 *       md: 'height-var-md',
 *       lg: 'height-var-lg',
 *       xl: 'height-var-xl',
 *     },
 *     {
 *       initial: '100px',
 *       md: '200px',
 *     },
 *   ],
 * });
 *
 * // resolves to
 * // {
 * //   classNames: 'height-class-initial height-class-md',
 * //   cssVars: {
 * //     '--height-var-initial': '100px',
 * //     '--height-var-md': '200px',
 * //   },
 * // }
 *
 * @param input
 * @returns
 */
export function extractDynamicStyles<
  T extends Record<string, OptionalMediaQuery<number | string | undefined>>,
  K extends keyof T,
>(input: Record<K, [FullResponsive<string>, InlineVarsObject, T[K]]>) {
  const cssProperties = Object.keys(input) as K[];
  const cssClassNames: Array<string> = [];
  const cssVarsObject: Record<string, string> = {};

  cssProperties.forEach(cssProperty => {
    const [classNames, variables, values] = input[cssProperty];

    if (!classNames || !values) return;

    if (isResponsive(values)) {
      const breakpoints = Object.keys(values) as MediaQueryKeys[];

      breakpoints.forEach(breakpoint => {
        if (classNames[breakpoint]) {
          cssClassNames.push(classNames[breakpoint]);
        }

        if (values[breakpoint]) {
          cssVarsObject[variables[breakpoint]] = values[breakpoint]!;
        }
      });
    } else {
      if (classNames['initial']) {
        cssClassNames.push(classNames['initial']);
      }

      cssVarsObject[variables['initial']] = `${values}`;
    }
  });

  return {
    classNames: cssClassNames,
    cssVars: assignInlineVars(cssVarsObject),
  };
}

/**
 * This utility generates vanilla extract variants for components. The keys in the tokens parameter will be used as a dictionary
 * to determine which variants should be generated and the property parameter will determine what CSS property the token is applied
 * to.
 *
 * Optionally, a theme parameter can be given that is used to map the tokens to the correct vanilla extract variable.
 *
 * @param tokens A tokens array with the desired variants
 * @param property The desired CSS property that the variants should apply
 * @param theme A Vanilla Extract theme object
 * @returns A Vanilla Extract object with the variants applying the given CSS property using the corresponding theme values
 *
 * @example
 * const tokens = ['backgroundPrimary','backgroundSecondary'];
 *
 * const theme = {
 *   backgroundPrimary: 'var(--colors-backgroundPrimary)',
 *   backgroundSecondary: 'var(--colors-backgroundSecondary)',
 *   textPrimary: 'var(--colors-textPrimary)',
 *   textInverted: 'var(--colors-textInverted)',
 * };
 *
 * const variants = createVariants(tokens, 'backgroundColor', theme);
 *
 * // variants = {
 * //   backgroundPrimary: { backgroundColor: 'var(--colors-backgroundPrimary)', },
 * //   backgroundSecondary: { backgroundColor: 'var(--colors-backgroundSecondary)', },
 * // };
 */
type ThemeType<T extends number | string> = { readonly [key in T]: string };

export function createVariants<
  TokenKey extends number | string,
  Theme extends ThemeType<TokenKey>,
  Property extends CSSProperty,
>(
  tokens: TokenKey[],
  property: Property,
  theme?: Partial<Theme>,
): { [Key in TokenKey | `${Extract<keyof Theme, number>}`]: { [Prop in Property]: TokenKey } } {
  return tokens.reduce(
    (accumulator, key) => {
      // Try to get the value from the theme object (which contains the vanilla extract variables), or fall back on the key for direct values
      const themeValue = theme?.[key] ?? key;

      type VariantValueType = { [Prop in Property]: Theme[typeof key] | typeof key };
      const variantValue = { [property]: themeValue } as VariantValueType;

      return {
        ...accumulator,
        [key as TokenKey]: variantValue,
        // make e.g. numeric keys also accessible as their string counterpart
        [key.toString() as TokenKey]: variantValue,
      };
    },
    {} as { [Key in TokenKey | `${Extract<keyof Theme, number>}`]: { [Prop in Property]: TokenKey } },
  );
}
