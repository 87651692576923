import { FC, ReactElement, useId } from 'react';

import type { RadioGroupItemProps } from '@radix-ui/react-radio-group';
import reactToText from 'react-to-text';

import { styled } from '../../../stitches.config';
import { Box } from '../../Box/Box';
import { Image as SparkyImage } from '../../Image/Image';
import { Stack } from '../../Stack/Stack';
import { Text } from '../../Text/Text';
import { RadioBase } from '../RadioBase';
import { useRadioGroup } from '../RadioGroup/RadioGroup';
import { RadioIndicator } from '../RadioIndicator';

const StyledRadio = styled(RadioBase, {
  '&:focus': {
    // Outline is handled in the styled Label
    outline: 'none',
  },
});

const TileWrapper = styled(Box, {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  gap: '$3',
  minHeight: '72px',
  paddingX: '$4',
});

const StyledChildren = styled(Box, {
  paddingY: '$3',
  flexGrow: 1,

  variants: {
    alignLabel: {
      center: {
        textAlign: 'center',
      },
      start: {
        // No styles needed
      },
    },
  },
});

/**
 * Label element for the input.
 */
const Label = styled('label', {
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: '$m',
  backgroundColor: '$backgroundPrimary',
  boxShadow: '$s',

  '&:hover': {
    boxShadow: '$shadowHover',
  },

  [`& ${StyledRadio}`]: {
    '&::before': {
      position: 'absolute',
      content: '',
      width: '100%',
      height: '100%',
      left: 0,
      borderRadius: '$m',
    },

    '&[data-state="checked"]': {
      '&::before': {
        border: '$borderWidths$m solid $borderSelected',
      },
    },

    '&:focus-visible': {
      '&::before': {
        outline: '$outlineFocus',
      },
    },

    '@supports not selector(:focus-visible)': {
      '&:focus': {
        '&::before': {
          outline: '$outlineFocus',
        },
      },
    },
  },
});

type ImageProps = Pick<
  Parameters<typeof SparkyImage>[0],
  'alt' | 'height' | 'maxHeight' | 'maxWidth' | 'src' | 'width'
>;

const Image: FC<ImageProps> = ({ ...props }) => <SparkyImage {...props} objectFit="contain"></SparkyImage>;

type RadioTileProps = Pick<RadioGroupItemProps, 'value'> & {
  alignLabel?: 'center' | 'start';
  children: ReactElement<typeof Image> | string;
  icon?: JSX.Element;
  subtitle?: string;
};
type RadioTileCompoundButtonProps = {
  Image: typeof Image;
};

export const RadioTile: FC<RadioTileProps> & RadioTileCompoundButtonProps = ({
  alignLabel = 'start',
  children,
  value,
  icon,
  subtitle,
}) => {
  const id = useId();
  const { ariaDescribedby } = useRadioGroup();

  return (
    <Label data-label={reactToText(children)}>
      <TileWrapper>
        <StyledRadio aria-describedby={ariaDescribedby} id={id} value={value}>
          <RadioIndicator />
        </StyledRadio>
        {icon ? <Stack.Item shrink={false}>{icon}</Stack.Item> : null}
        <StyledChildren alignLabel={alignLabel}>
          <Text weight="bold">{children}</Text>
          {subtitle && (
            <Text weight="regular" size="BodyM">
              {subtitle}
            </Text>
          )}
        </StyledChildren>
      </TileWrapper>
    </Label>
  );
};

StyledRadio.displayName = 'styled(RadioBase)';
Label.displayName = 'Label';

RadioTile.Image = Image;
RadioTile.Image.displayName = 'RadioTile.Image';
