import React from 'react';

import { styled } from '../../stitches.config';
import { DynamicVariantProp } from '../../types';
import { extractCssProps } from '../../util/css/stitches';

const GridItemStitched = styled('div', {});

const sparkyClassName = 'sparky-grid-item';

export const GridItem = React.forwardRef<HTMLDivElement, React.PropsWithChildren<GridItemProps>>(
  ({ children, as = 'div', className = '', ...props }, ref) => {
    const cssProps = extractCssProps(props);
    return (
      <GridItemStitched css={cssProps} as={as} className={`${sparkyClassName} ${className}`} ref={ref}>
        {children}
      </GridItemStitched>
    );
  },
);

GridItem.toString = () => `.${sparkyClassName}`;
GridItemStitched.displayName = 'styled(GridItem)';
GridItem.displayName = 'GridItem';

type GridItemProps = {
  /** This is the [native grid-area CSS property](https://www.w3schools.com/cssref/pr_grid-area.asp) that will be directly passed on to the grid item.   */
  gridArea?: DynamicVariantProp<'gridArea'>;
  /** This is the [native grid-column-start CSS property](https://www.w3schools.com/cssref/pr_grid-column-start.asp) that will be directly passed on to the grid item.   */
  gridColumnStart?: DynamicVariantProp<'gridColumnStart'>;
  /** This is the [nativegrid-column-end CSS property](https://www.w3schools.com/cssref/pr_grid-column-end.asp) that will be directly passed on to the grid item.   */
  gridColumnEnd?: DynamicVariantProp<'gridColumnEnd'>;
  /** This is the [native grid-column CSS property](https://www.w3schools.com/cssref/pr_grid-column.asp) that will be directly passed on to the grid item. */
  gridColumn?: DynamicVariantProp<'gridColumn'>;
  /** This is the [native grid-row-start CSS property](https://www.w3schools.com/cssref/pr_grid-row-start.asp) that will be directly passed on to the grid item.   */
  gridRowStart?: DynamicVariantProp<'gridRowStart'>;
  /** This is the [native grid-row-end CSS property](https://www.w3schools.com/cssref/pr_grid-row-end.asp) that will be directly passed on to the grid item.   */
  gridRowEnd?: DynamicVariantProp<'gridRowEnd'>;
  /** This is the [native grid-row CSS property](https://www.w3schools.com/cssref/pr_grid-row.asp) that will be directly passed on to the grid item.   */
  gridRow?: DynamicVariantProp<'gridRow'>;
  /** This is the [native order CSS property](https://www.w3schools.com/cssref/css3_pr_order.asp) that will be directly passed on to the grid item.   */
  order?: DynamicVariantProp<'order'>;
  /** This is the [native order CSS property](https://www.w3schools.com/cssref/css3_pr_align-self.php) that will be directly passed on to the grid item.   */
  alignSelf?: DynamicVariantProp<'alignSelf'>;
  justifySelf?: DynamicVariantProp<'justifySelf'>;
  as?: keyof JSX.IntrinsicElements;
  className?: never;
};
