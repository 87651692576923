import { FC, useCallback, useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { CloseIcon } from '../../icons';
import { useI18nTranslation } from '../../providers/i18n';
import { styled, keyframes } from '../../stitches.config';
import { Box } from '../Box/Box';
import { sharedPlainButtonStyles } from '../MobileHeader/MobileHeader';
import { Text } from '../Text/Text';

const BackgroundFadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const BackgroundFadeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

const MenuSlideIn = keyframes({
  '0%': {
    transform: 'translateX($$animationScope)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
});

const MenuSlideOut = keyframes({
  '0%': {
    transform: 'translateX(0)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateX($$animationScope)',
    opacity: 0,
  },
});

const StyledProfileMenu = styled('div', {
  backgroundColor: '$backgroundPrimary',
  $$animationDuration: '0.01ms',
  '@safeMotion': {
    $$animationDuration: '.3s',
  },
  overflowY: 'auto',
  zIndex: 1,

  variants: {
    isDesktop: {
      true: {
        width: '500px',
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
        borderLeft: '1px solid $borderDividerLowEmphasis',
        $$animationScope: '500px',
        boxShadow: '$l',
      },
      false: {
        width: '100vw',
        height: '100%',
        $$animationScope: '100vw',
        position: 'fixed',
        top: 0,
        right: 0,
      },
    },
    isActive: {
      true: {
        animation: `${MenuSlideIn} $$animationDuration ease-in forwards`,
      },
      false: {
        animation: `${MenuSlideOut} $$animationDuration ease-in forwards`,
      },
    },
  },
});

const StyledBackdrop = styled(Dialog.Overlay, {
  $$fadeDuration: '0.01ms',
  '@safeMotion': {
    $$fadeDuration: '400ms',
  },
  position: 'fixed',
  top: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: '$backgroundScrim',
  zIndex: 1,
  variants: {
    isActive: {
      true: {
        animation: `${BackgroundFadeIn} $$fadeDuration ease-in forwards`,
      },
      false: {
        pointerEvents: 'none',
        animation: `${BackgroundFadeOut} $$fadeDuration ease-in forwards`,
      },
    },
  },
});

const StyledContent = styled(Dialog.Content, {
  zIndex: 1,
});

const StyledCloseButton = styled('button', sharedPlainButtonStyles, {
  position: 'absolute',
  right: '$2',
  top: '$2',
});

type SideNavProps = {
  isDesktop: boolean;
  /** Controlled state of the SideNav */
  isOpen: boolean;
  isCloseButtonVisible?: boolean;
  /** External state setter that is triggered when the menu is closed */
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
};

export const SideNav: FC<SideNavProps> = ({ isOpen, setIsOpen, children, isDesktop, isCloseButtonVisible = true }) => {
  const onAnimationEnd = useCallback(() => {
    if (!isOpen) setIsVisible(false);
  }, [isOpen]);

  const closeText = useI18nTranslation('close');
  const capitalizedCloseText = closeText.charAt(0).toUpperCase() + closeText.slice(1);

  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen && !isVisible) {
      setIsVisible(true);
    }

    isOpen && window.scrollTo(0, 0);
  }, [isOpen, isVisible]);

  if (!isVisible) return null;

  const CloseButton: FC = () => (
    <StyledCloseButton onClick={() => setIsOpen(false)}>
      <CloseIcon />
      <Box paddingLeft="2">
        <Text weight="bold">{capitalizedCloseText}</Text>
      </Box>
    </StyledCloseButton>
  );

  return isDesktop ? (
    <Dialog.Root onOpenChange={setIsOpen} open>
      <Dialog.Portal>
        <StyledBackdrop isActive={isOpen}>
          <StyledContent>
            <StyledProfileMenu
              isDesktop={isDesktop}
              data-cy="dropMenu"
              isActive={isOpen}
              onAnimationEnd={onAnimationEnd}>
              {isCloseButtonVisible && <CloseButton />}
              {children}
            </StyledProfileMenu>
          </StyledContent>
        </StyledBackdrop>
      </Dialog.Portal>
    </Dialog.Root>
  ) : (
    <StyledProfileMenu data-cy="dropMenu" isDesktop={isDesktop} isActive={isOpen} onAnimationEnd={onAnimationEnd}>
      {isCloseButtonVisible && <CloseButton />}
      {children}
    </StyledProfileMenu>
  );
};
