import { type FC, useState, useEffect } from 'react';

import {
  imageStyle,
  breakpointVariants,
  aspectRatioVars,
  borderRadiusVars,
  heightVars,
  objectPositionVars,
  maxHeightVars,
  maxWidthVars,
  minHeightVars,
  minWidthVars,
  widthVars,
} from './Image.css';
import { getAspectRatioDimensions } from './utils';
import { mediaQueries } from '../../sparky.config';
import { BreakPointTypes, DynamicVariantProp, MediaQueryKeys, OptionalMediaQuery, Responsive } from '../../types';
import { getClassNames } from '../../util/css';
import { extractDynamicStyles } from '../../util/css/vanilla-extract';
import { displayRecipe } from '../../util/css/vanilla-extract/shared/display.css';
import { objectFitRecipe } from '../../util/css/vanilla-extract/shared/object.css';

type MQProp = {
  [key in MediaQueryKeys]?: string;
};

type ImageResponsive = Responsive<string>;

type ResponsiveSrcProps = {
  sizes: ImageResponsive /* Define the width of the image at breakpoints to ensure proper function of srcSet. */;
  sources?: never /* Maps to a `picture` element with `source` elements */;
  srcSet: string /* Native HTML scrSet attribute. Specify both the source and width or pixel density. https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-srcset */;
};

type StaticSrcProps = {
  sizes?: never;
  sources?: MQProp /* Maps to a `picture` element with `source` elements */;
  srcSet?: never;
};

type Props = {
  alt: string;
  aspectRatio?: DynamicVariantProp<'aspectRatio'> /* Important to set to refrain document rendering from [shifting too much](https://developer.mozilla.org/en-US/docs/Learn/Performance/Multimedia#rendering_strategy_preventing_jank_when_loading_images). */;
  borderRadius?: BorderRadiusInput;
  children?: never;
  className?: never;
  display?: DynamicVariantProp<'display'>;
  fallbackSrc?: string /* Source that will be used when any src fails to load */;
  hasLazyLoad?: boolean /* Native browser lazy loading. Not supported on older browsers. https://caniuse.com/loading-lazy-attr */;
  height?: DynamicVariantProp<'height'>;
  maxHeight?: DynamicVariantProp<'maxHeight'>;
  maxWidth?: DynamicVariantProp<'maxWidth'>;
  minHeight?: DynamicVariantProp<'minHeight'>;
  minWidth?: DynamicVariantProp<'minWidth'>;
  objectFit?: DynamicVariantProp<'objectFit'>;
  objectPosition?: DynamicVariantProp<'objectPosition'>;
  src: string;
  width?: DynamicVariantProp<'width'>;
} & (ResponsiveSrcProps | StaticSrcProps);

// We need to map a Responsive<string> structure to `[breakpoint] [value], [...], [initialValue]`.
// { initial: '100vw', lg: '720px', xl: '1000px' } => `(min-width: 1024px) 720px, (min-width: 1440px) 1000px, 100vw`
export const sizesToString = (sizes: ImageResponsive) => {
  const keys = Object.keys(mediaQueries) as BreakPointTypes[];
  return keys
    .filter(k => Object.keys(sizes).includes(k))
    .map(mqk => `${[mediaQueries[mqk]]} ${sizes[mqk]}`)
    .concat(sizes.initial ?? '')
    .join(', ');
};

type BorderRadiusValue = 'square' | 'rounded';
type BorderRadiusArray = Array<BorderRadiusValue>;
type BorderRadiusInput = BorderRadiusValue | BorderRadiusArray | Responsive<BorderRadiusValue | BorderRadiusArray>;

const BORDER_VALUES = { rounded: '16px', square: '0px' };

const convertBorderRadius = (input: BorderRadiusInput): OptionalMediaQuery<string> => {
  // Helper function to convert array to a CSS string
  const mapInputToString = (input: BorderRadiusValue | BorderRadiusArray): string =>
    typeof input === 'string' ? BORDER_VALUES[input] : input.map(value => BORDER_VALUES[value]).join(' ');

  // If input is an array, return its converted string
  if (typeof input === 'string' || Array.isArray(input)) {
    return mapInputToString(input);
  }

  // If input is an object, map each breakpoint value to a string
  const result: Responsive<string> = {};
  for (const [key, value] of Object.entries(input)) {
    result[key as MediaQueryKeys] = mapInputToString(value);
  }

  return result;
};

const sparkyImageClassName = 'img-sparky';

export const Image: FC<Props> = ({
  alt,
  aspectRatio,
  borderRadius = 'square',
  className = '',
  display,
  fallbackSrc,
  hasLazyLoad = true,
  height = 'auto',
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  objectFit,
  objectPosition,
  sizes,
  sources,
  src,
  srcSet,
  width = 'auto',
}) => {
  const [hasError, setHasError] = useState(false);

  const { classNames: dynamicCssClasses, cssVars } = extractDynamicStyles({
    aspectRatio: [breakpointVariants.aspectRatio, aspectRatioVars, aspectRatio],
    borderRadius: [breakpointVariants.borderRadius, borderRadiusVars, convertBorderRadius(borderRadius)],
    height: [breakpointVariants.height, heightVars, height],
    objectPosition: [breakpointVariants.objectPosition, objectPositionVars, objectPosition],
    maxHeight: [breakpointVariants.maxHeight, maxHeightVars, maxHeight],
    maxWidth: [breakpointVariants.maxWidth, maxWidthVars, maxWidth],
    minHeight: [breakpointVariants.minHeight, minHeightVars, minHeight],
    minWidth: [breakpointVariants.minWidth, minWidthVars, minWidth],
    width: [breakpointVariants.width, widthVars, width],
  });

  const classNames = getClassNames(
    sparkyImageClassName,
    className,
    imageStyle,
    displayRecipe({ display }),
    objectFitRecipe({ objectFit }),
    ...dynamicCssClasses,
  );

  const aspectRatioDimensions = getAspectRatioDimensions({ aspectRatio, height, width });

  useEffect(() => {
    setHasError(false);
  }, [src, srcSet, sources]);

  function handleSrcError() {
    setHasError(true);
  }

  if (hasError && fallbackSrc) {
    return <img alt={alt} className={`${classNames}`} src={fallbackSrc} style={cssVars} {...aspectRatioDimensions} />;
  }

  if (sources && Object.keys(sources).length > 0) {
    const sourceEntries = Object.entries(sources) as Array<Array<BreakPointTypes>>;
    const sourceElements = sourceEntries.map(([key, value], i) => (
      <source key={i} media={mediaQueries[key]} srcSet={value} />
    ));

    const children = [
      ...sourceElements,
      <img
        alt={alt}
        className={classNames}
        key="img"
        loading={hasLazyLoad ? 'lazy' : undefined}
        onError={handleSrcError}
        sizes={sizes ? sizesToString(sizes) : undefined}
        src={src}
        srcSet={srcSet}
        style={cssVars}
        {...aspectRatioDimensions}
      />,
    ];

    return <picture>{children}</picture>;
  }

  return (
    <img
      alt={alt}
      className={classNames}
      loading={hasLazyLoad ? 'lazy' : undefined}
      onError={handleSrcError}
      sizes={sizes ? sizesToString(sizes) : undefined}
      src={src}
      srcSet={srcSet}
      style={cssVars}
      {...aspectRatioDimensions}
    />
  );
};
